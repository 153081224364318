import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { navigate, Link } from 'gatsby';
import Loading from '../../1TalkDesign/Loading';
import { getPasscode } from '../../../services/user.service';
import phoneValidation from 'phone';
import { getSearchParamsStrings, getURLSearchParamByKey } from 'src/utils';
import { t } from 'src/i18n/config';
import { PhoneInputField, InputContainer } from '../components/Fields';
import Button from '../components/Button';

const AgreementContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.font_size.sm};
  font-weight: ${({ theme }) => theme.font_weight.medium};
  gap: 4px;
  input {
    height: 16px;
    width: 16px;
    background-color: ${({ theme }) => theme.colors.primaryColor[600]};
  }
  a {
    color: ${({ theme }) => theme.colors.primaryColor[600]};
  }
`;

const validatePhone = ({ countryCode, number }) => {
  if (number === '') {
    throw Error(t('enterPhoneNumber'));
  }
  if (countryCode === '+886' && number.startsWith(0)) {
    return `${countryCode}${number.substring(1)}`;
  }
  return `${countryCode}${number}`;
};

const getPhoneData = (phone = '') => {
  const phoneData = phoneValidation(phone);
  if (phone.startsWith('+') && phoneData.isValid) {
    let number = phone.replace(phoneData.countryCode, '');
    if (phoneData.countryCode === '+886') {
      number = number.replace(/^9/, '09');
    }
    return {
      countryCode: phoneData.countryCode,
      number,
    };
  }

  return {
    countryCode: '+886',
    number: phone,
  };
};

const getDefaultPhone = (locale) => {
  return {
    countryCode: locale === 'jp' ? '+81' : '+886',
    number: '',
    id: locale ? locale.toUpperCase() : 'TW',
  };
};

const PhoneInput = ({ user }) => {
  const locale = getURLSearchParamByKey('locale');
  const buttonText = t('sendVerificationCode');
  const searchParams = getSearchParamsStrings();
  const [phone, setPhone] = useState(getDefaultPhone(locale));
  const [loading, setLoading] = useState(false);
  const handleSendValidationCode = async () => {
    setLoading(true);
    try {
      const validatedPhone = validatePhone(phone);
      await getPasscode({ phone: validatedPhone });
      await navigate(`/users/binding/validation${searchParams}`, {
        state: { phone: validatedPhone },
      });
    } catch (error) {
      alert(error.message);
      setLoading(false);
    }
  };

  const handleCountry = (id, countryCode) => {
    setPhone((phone) => ({
      ...phone,
      id,
      countryCode,
    }));
  };
  const handlePhoneNumber = (value) => {
    setPhone((phone) => ({
      ...phone,
      number: value,
    }));
  };

  useEffect(() => {
    const phoneData = getPhoneData(user && (user.Phone || undefined));
    setPhone(phoneData);
  }, [user]);

  return (
    <InputContainer>
      <PhoneInputField
        local={locale}
        code={phone.countryCode}
        countryId={phone.id}
        phoneNumber={phone.number}
        onPhoneChange={handlePhoneNumber}
        onCountryChange={handleCountry}
      />
      <AgreementContainer>
        <input type="checkbox" checked />
        {t('agreeToTerms')}
        <Link href="https://www.notion.so/alleypin/5e27a1937af44ce5b9bbcf1f593956cf">
          {t('privacyPolicy')}
        </Link>
      </AgreementContainer>
      <Button disabled={loading} onClick={handleSendValidationCode}>
        {loading ? <Loading /> : buttonText}
      </Button>
    </InputContainer>
  );
};
export default PhoneInput;
